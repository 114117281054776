define("huvepharma-aviapp-admin/base/enum/farm-housing-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "FLOOR_WINTERGARDEN": "floor_wintergarden",
    "FLOOR_FREE_RANGE": "floor_free_range",
    "FLOOR_OPEN": "floor_open",
    "FLOOR_CLOSED": "floor_closed",
    "FLOOR_SLATTED_NET": "floor_slatted_net",
    "CAGES_TRADITIONAL": "cages_traditional",
    "CAGES_ENRICHED": "cages_enriched",
    "CAGES_COLONY_NEST": "cages_colony_nest",
    "CAGES_AUTO_HARVEST": "cages_auto_harvest",
    "CAGES_MANUAL_HARVEST": "cages_manual_harvest",
    "AVIARY_WINTERGARDEN": "aviary_wintergarden",
    "AVIARY_FREE_RANGE": "aviary_free_range",
    "AVIARY": "aviary",
    "ORGANIC": "organic"
  };
  _exports.default = _default;
});