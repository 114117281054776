define("huvepharma-aviapp-admin/base/enum/countries/en-km", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "KH",
    "country": "កម្ពុជា"
  }, {
    "code": "CA",
    "country": "កាណាដា"
  }, {
    "code": "QA",
    "country": "កាតា"
  }, {
    "code": "CV",
    "country": "កាប់វែរ"
  }, {
    "code": "CM",
    "country": "កាមេរូន"
  }, {
    "code": "KZ",
    "country": "កាហ្សាក់ស្ថាន"
  }, {
    "code": "CG",
    "country": "កុងហ្គោ - ប្រាហ្សាវីល"
  }, {
    "code": "CD",
    "country": "កុងហ្គោ- គីនស្ហាសា"
  }, {
    "code": "CI",
    "country": "កូតឌីវ័រ"
  }, {
    "code": "KM",
    "country": "កូម័រ"
  }, {
    "code": "CW",
    "country": "កូរ៉ាកៅ"
  }, {
    "code": "KP",
    "country": "កូរ៉េខាងជើង"
  }, {
    "code": "KR",
    "country": "កូរ៉េខាងត្បូង"
  }, {
    "code": "KW",
    "country": "កូវ៉ែត"
  }, {
    "code": "CR",
    "country": "កូស្តារីកា"
  }, {
    "code": "CO",
    "country": "កូឡុំប៊ី"
  }, {
    "code": "KG",
    "country": "កៀហ្ស៊ីស៊ីស្ថាន"
  }, {
    "code": "KE",
    "country": "កេនយ៉ា"
  }, {
    "code": "CC",
    "country": "កោះកូកូស (គីលីង)"
  }, {
    "code": "KY",
    "country": "កោះកៃម៉ង់"
  }, {
    "code": "CK",
    "country": "កោះខូក"
  }, {
    "code": "CX",
    "country": "កោះគ្រីស្មាស"
  }, {
    "code": "NF",
    "country": "កោះណ័រហ្វក់"
  }, {
    "code": "TC",
    "country": "កោះទួគ និង កៃកូស"
  }, {
    "code": "BV",
    "country": "កោះប៊ូវ៉េត"
  }, {
    "code": "PN",
    "country": "កោះភីតកាន"
  }, {
    "code": "MP",
    "country": "កោះម៉ារីណាខាងជើង"
  }, {
    "code": "MH",
    "country": "កោះម៉ាស់សល"
  }, {
    "code": "VG",
    "country": "កោះវឺជិនចក្រភពអង់គ្លេស"
  }, {
    "code": "VI",
    "country": "កោះវឺជីនអាមេរិក"
  }, {
    "code": "SB",
    "country": "កោះសូឡូម៉ុង"
  }, {
    "code": "HM",
    "country": "កោះហឺដ និងម៉ាក់ដូណាល់"
  }, {
    "code": "FK",
    "country": "កោះហ្វក់ឡែន"
  }, {
    "code": "FO",
    "country": "កោះហ្វារ៉ូ"
  }, {
    "code": "GS",
    "country": "កោះហ្សកហ្ស៊ីខាងត្បូង និង សង់វិចខាងត្បូង"
  }, {
    "code": "AX",
    "country": "កោះអាឡង់"
  }, {
    "code": "UM",
    "country": "កោះអៅឡាយីងអាមេរិក"
  }, {
    "code": "GR",
    "country": "ក្រិក"
  }, {
    "code": "HR",
    "country": "ក្រូអាស៊ី"
  }, {
    "code": "GT",
    "country": "ក្វាតេម៉ាឡា"
  }, {
    "code": "KI",
    "country": "គិរីបាទី"
  }, {
    "code": "CU",
    "country": "គុយបា"
  }, {
    "code": "GB",
    "country": "ចក្រភពអង់គ្លេស"
  }, {
    "code": "CN",
    "country": "ចិន"
  }, {
    "code": "TD",
    "country": "ឆាដ"
  }, {
    "code": "CZ",
    "country": "ឆែគា"
  }, {
    "code": "JP",
    "country": "ជប៉ុន"
  }, {
    "code": "DJ",
    "country": "ជីប៊ូទី"
  }, {
    "code": "JE",
    "country": "ជឺស៊ី"
  }, {
    "code": "DK",
    "country": "ដាណឺម៉ាក"
  }, {
    "code": "DM",
    "country": "ដូមីនីក"
  }, {
    "code": "TF",
    "country": "ដែនដីបារាំងនៅភាគខាងត្បូង"
  }, {
    "code": "PS",
    "country": "ដែនដីប៉ាលេស្ទីន"
  }, {
    "code": "IO",
    "country": "ដែនដីអង់គ្លេសនៅមហាសមុទ្រឥណ្ឌា"
  }, {
    "code": "NA",
    "country": "ណាមីប៊ី"
  }, {
    "code": "NR",
    "country": "ណូរូ"
  }, {
    "code": "NU",
    "country": "ណៀ"
  }, {
    "code": "TZ",
    "country": "តង់សានី"
  }, {
    "code": "TJ",
    "country": "តាហ្ស៊ីគីស្ថាន"
  }, {
    "code": "TO",
    "country": "តុងហ្គា"
  }, {
    "code": "TK",
    "country": "តូខេឡៅ"
  }, {
    "code": "TG",
    "country": "តូហ្គោ"
  }, {
    "code": "TR",
    "country": "តួកគី"
  }, {
    "code": "TM",
    "country": "តួកម៉េនីស្ថាន"
  }, {
    "code": "TW",
    "country": "តៃវ៉ាន់"
  }, {
    "code": "TH",
    "country": "ថៃ"
  }, {
    "code": "TL",
    "country": "ទីម័រលេស្តេ"
  }, {
    "code": "TN",
    "country": "ទុយនីស៊ី"
  }, {
    "code": "TV",
    "country": "ទូវ៉ាលូ"
  }, {
    "code": "TT",
    "country": "ទ្រីនីដាត និងតូបាហ្គោ"
  }, {
    "code": "NO",
    "country": "ន័រវែស"
  }, {
    "code": "NI",
    "country": "នីការ៉ាហ្គា"
  }, {
    "code": "NE",
    "country": "នីហ្សេ"
  }, {
    "code": "NG",
    "country": "នីហ្សេរីយ៉ា"
  }, {
    "code": "NC",
    "country": "នូវែលកាឡេដូនី"
  }, {
    "code": "NZ",
    "country": "នូវែលសេឡង់"
  }, {
    "code": "NP",
    "country": "នេប៉ាល់"
  }, {
    "code": "BD",
    "country": "បង់ក្លាដែស"
  }, {
    "code": "PK",
    "country": "ប៉ាគីស្ថាន"
  }, {
    "code": "PA",
    "country": "ប៉ាណាម៉ា"
  }, {
    "code": "BB",
    "country": "បាបាដុស"
  }, {
    "code": "PG",
    "country": "ប៉ាពូអាស៊ីនូវែលហ្គីណេ"
  }, {
    "code": "PY",
    "country": "ប៉ារ៉ាហ្គាយ"
  }, {
    "code": "BH",
    "country": "បារ៉ែន"
  }, {
    "code": "FR",
    "country": "បារាំង"
  }, {
    "code": "BS",
    "country": "បាហាម៉ា"
  }, {
    "code": "BM",
    "country": "ប៊ឺមុយដា"
  }, {
    "code": "BW",
    "country": "បុតស្វាណា"
  }, {
    "code": "VA",
    "country": "បុរីវ៉ាទីកង់"
  }, {
    "code": "BG",
    "country": "ប៊ុលហ្គារី"
  }, {
    "code": "BT",
    "country": "ប៊ូតង់"
  }, {
    "code": "BI",
    "country": "ប៊ូរុនឌី"
  }, {
    "code": "PF",
    "country": "ប៉ូលីណេស៊ីបារាំង"
  }, {
    "code": "BO",
    "country": "បូលីវី"
  }, {
    "code": "BA",
    "country": "បូស្នី និងហឺហ្សីហ្គូវីណា"
  }, {
    "code": "PL",
    "country": "ប៉ូឡូញ"
  }, {
    "code": "BF",
    "country": "បួគីណាហ្វាសូ"
  }, {
    "code": "BJ",
    "country": "បេណាំង"
  }, {
    "code": "PE",
    "country": "ប៉េរូ"
  }, {
    "code": "BZ",
    "country": "បេលី"
  }, {
    "code": "BY",
    "country": "បេឡារុស"
  }, {
    "code": "BE",
    "country": "បែលហ្ស៊ិក"
  }, {
    "code": "BR",
    "country": "ប្រេស៊ីល"
  }, {
    "code": "PW",
    "country": "ផៅឡូ"
  }, {
    "code": "PR",
    "country": "ព័រតូរីកូ"
  }, {
    "code": "PT",
    "country": "ព័រទុយហ្គាល់"
  }, {
    "code": "BN",
    "country": "ព្រុយណេ"
  }, {
    "code": "MO",
    "country": "ម៉ាកាវ តំបន់រដ្ឋបាលពិសេសចិន"
  }, {
    "code": "MG",
    "country": "ម៉ាដាហ្គាស្កា"
  }, {
    "code": "MQ",
    "country": "ម៉ាទីនីក"
  }, {
    "code": "YT",
    "country": "ម៉ាយុត"
  }, {
    "code": "MA",
    "country": "ម៉ារ៉ុក"
  }, {
    "code": "MV",
    "country": "ម៉ាល់ឌីវ"
  }, {
    "code": "MT",
    "country": "ម៉ាល់ត៍"
  }, {
    "code": "ML",
    "country": "ម៉ាលី"
  }, {
    "code": "MK",
    "country": "ម៉ាសេដ្វានខាងជើង"
  }, {
    "code": "MW",
    "country": "ម៉ាឡាវី"
  }, {
    "code": "MY",
    "country": "ម៉ាឡេស៊ី"
  }, {
    "code": "MX",
    "country": "ម៉ិកស៊ិក"
  }, {
    "code": "FM",
    "country": "មីក្រូណេស៊ី"
  }, {
    "code": "MM",
    "country": "មីយ៉ាន់ម៉ា (ភូមា)"
  }, {
    "code": "ME",
    "country": "ម៉ុងតេណេហ្គ្រោ"
  }, {
    "code": "MS",
    "country": "ម៉ុងស៊ែរ៉ា"
  }, {
    "code": "MN",
    "country": "ម៉ុងហ្គោលី"
  }, {
    "code": "MD",
    "country": "ម៉ុលដាវី"
  }, {
    "code": "MC",
    "country": "ម៉ូណាកូ"
  }, {
    "code": "MR",
    "country": "ម៉ូរីតានី"
  }, {
    "code": "MU",
    "country": "ម៉ូរីស"
  }, {
    "code": "MZ",
    "country": "ម៉ូសំប៊ិក"
  }, {
    "code": "YE",
    "country": "យេម៉ែន"
  }, {
    "code": "RW",
    "country": "រវ៉ាន់ដា"
  }, {
    "code": "RU",
    "country": "រុស្ស៊ី"
  }, {
    "code": "RO",
    "country": "រូម៉ានី"
  }, {
    "code": "RE",
    "country": "រេអុយញ៉ុង"
  }, {
    "code": "LI",
    "country": "លិចតិនស្ដាញ"
  }, {
    "code": "LT",
    "country": "លីទុយអានី"
  }, {
    "code": "LB",
    "country": "លីបង់"
  }, {
    "code": "LY",
    "country": "លីប៊ី"
  }, {
    "code": "LR",
    "country": "លីបេរីយ៉ា"
  }, {
    "code": "LU",
    "country": "លុចសំបួ"
  }, {
    "code": "VU",
    "country": "វ៉ានូទូ"
  }, {
    "code": "WF",
    "country": "វ៉ាលីស និងហ្វូទូណា"
  }, {
    "code": "VN",
    "country": "វៀតណាម"
  }, {
    "code": "VE",
    "country": "វ៉េណេស៊ុយអេឡា"
  }, {
    "code": "PM",
    "country": "សង់ព្យែរ និងមីគីឡុង"
  }, {
    "code": "SH",
    "country": "សង់ហេឡេណា"
  }, {
    "code": "US",
    "country": "សហរដ្ឋអាមេរិក"
  }, {
    "code": "DO",
    "country": "សាធារណរដ្ឋដូមីនីក"
  }, {
    "code": "CF",
    "country": "សាធារណរដ្ឋអាហ្វ្រិកកណ្ដាល"
  }, {
    "code": "SM",
    "country": "សានម៉ារីណូ"
  }, {
    "code": "WS",
    "country": "សាម័រ"
  }, {
    "code": "AS",
    "country": "សាម័រ អាមេរិកាំង"
  }, {
    "code": "EH",
    "country": "សាហារ៉ាខាងលិច"
  }, {
    "code": "SG",
    "country": "សិង្ហបុរី"
  }, {
    "code": "SX",
    "country": "សីងម៉ាធីន"
  }, {
    "code": "CY",
    "country": "ស៊ីប"
  }, {
    "code": "ZW",
    "country": "ស៊ីមបាវ៉េ"
  }, {
    "code": "SY",
    "country": "ស៊ីរី"
  }, {
    "code": "CL",
    "country": "ស៊ីលី"
  }, {
    "code": "SC",
    "country": "សីស្ហែល"
  }, {
    "code": "SE",
    "country": "ស៊ុយអែត"
  }, {
    "code": "SD",
    "country": "ស៊ូដង់"
  }, {
    "code": "SS",
    "country": "ស៊ូដង់ខាងត្បូង"
  }, {
    "code": "SO",
    "country": "សូម៉ាលី"
  }, {
    "code": "SR",
    "country": "សូរីណាម"
  }, {
    "code": "SL",
    "country": "សៀរ៉ាឡេអូន"
  }, {
    "code": "SN",
    "country": "សេណេហ្គាល់"
  }, {
    "code": "RS",
    "country": "សែប៊ី"
  }, {
    "code": "ST",
    "country": "សៅតូម៉េ និង ប្រាំងស៊ីប"
  }, {
    "code": "ZM",
    "country": "សំប៊ី"
  }, {
    "code": "KN",
    "country": "សាំងគីត និង ណេវីស"
  }, {
    "code": "BL",
    "country": "សាំងបាថេឡេមី"
  }, {
    "code": "MF",
    "country": "សាំងម៉ាទីន"
  }, {
    "code": "LC",
    "country": "សាំងលូស៊ី"
  }, {
    "code": "VC",
    "country": "សាំងវ៉ាំងសង់ និង ហ្គ្រេណាឌីន"
  }, {
    "code": "LK",
    "country": "ស្រីលង្កា"
  }, {
    "code": "SK",
    "country": "ស្លូវ៉ាគី"
  }, {
    "code": "SI",
    "country": "ស្លូវេនី"
  }, {
    "code": "SJ",
    "country": "ស្វាលបាដ និង ហ្សង់ម៉ាយេន"
  }, {
    "code": "SZ",
    "country": "ស្វាស៊ីឡង់"
  }, {
    "code": "CH",
    "country": "ស្វីស"
  }, {
    "code": "HK",
    "country": "ហុងកុង"
  }, {
    "code": "HU",
    "country": "ហុងគ្រី"
  }, {
    "code": "HN",
    "country": "ហុងឌូរ៉ាស"
  }, {
    "code": "NL",
    "country": "ហូឡង់"
  }, {
    "code": "BQ",
    "country": "ហូឡង់ ការ៉ាប៊ីន"
  }, {
    "code": "HT",
    "country": "ហៃទី"
  }, {
    "code": "GH",
    "country": "ហ្គាណា"
  }, {
    "code": "GA",
    "country": "ហ្គាបុង"
  }, {
    "code": "GN",
    "country": "ហ្គីណេ"
  }, {
    "code": "GW",
    "country": "ហ្គីណេប៊ីស្សូ"
  }, {
    "code": "GQ",
    "country": "ហ្គីណេអេក្វាទ័រ"
  }, {
    "code": "GY",
    "country": "ហ្គីយ៉ាន"
  }, {
    "code": "GF",
    "country": "ហ្គីអាណា បារាំង"
  }, {
    "code": "GG",
    "country": "ហ្គេនស៊ី"
  }, {
    "code": "GP",
    "country": "ហ្គោដឺឡុប"
  }, {
    "code": "GM",
    "country": "ហ្គំប៊ី"
  }, {
    "code": "GU",
    "country": "ហ្គាំ"
  }, {
    "code": "GD",
    "country": "ហ្គ្រើណាដ"
  }, {
    "code": "GL",
    "country": "ហ្គ្រោអង់ឡង់"
  }, {
    "code": "FJ",
    "country": "ហ្វីជី"
  }, {
    "code": "PH",
    "country": "ហ្វីលីពីន"
  }, {
    "code": "FI",
    "country": "ហ្វាំងឡង់"
  }, {
    "code": "JO",
    "country": "ហ៊្សកដានី"
  }, {
    "code": "GE",
    "country": "ហ្សកហ្ស៊ី"
  }, {
    "code": "JM",
    "country": "ហ្សាម៉ាអ៊ីក"
  }, {
    "code": "GI",
    "country": "ហ្ស៊ីប្រាល់តា"
  }, {
    "code": "LA",
    "country": "ឡាវ"
  }, {
    "code": "LV",
    "country": "ឡេតូនី"
  }, {
    "code": "LS",
    "country": "ឡេសូតូ"
  }, {
    "code": "AD",
    "country": "អង់ដូរ៉ា"
  }, {
    "code": "AQ",
    "country": "អង់តាក់ទិក"
  }, {
    "code": "AG",
    "country": "អង់ទីហ្គា និង បាប៊ុយដា"
  }, {
    "code": "AI",
    "country": "អង់ហ្គីឡា"
  }, {
    "code": "AO",
    "country": "អង់ហ្គោឡា"
  }, {
    "code": "AM",
    "country": "អាមេនី"
  }, {
    "code": "SA",
    "country": "អារ៉ាប៊ីសាអូឌីត"
  }, {
    "code": "AW",
    "country": "អារូបា"
  }, {
    "code": "AL",
    "country": "អាល់បានី"
  }, {
    "code": "DZ",
    "country": "អាល់ហ្សេរី"
  }, {
    "code": "DE",
    "country": "អាល្លឺម៉ង់"
  }, {
    "code": "AZ",
    "country": "អាស៊ែបៃហ្សង់"
  }, {
    "code": "AF",
    "country": "អាហ្វហ្គានីស្ថាន"
  }, {
    "code": "ZA",
    "country": "អាហ្វ្រិកខាងត្បូង"
  }, {
    "code": "AR",
    "country": "អាហ្សង់ទីន"
  }, {
    "code": "IN",
    "country": "ឥណ្ឌា"
  }, {
    "code": "ID",
    "country": "ឥណ្ឌូណេស៊ី"
  }, {
    "code": "IT",
    "country": "អ៊ីតាលី"
  }, {
    "code": "IR",
    "country": "អ៊ីរ៉ង់"
  }, {
    "code": "IQ",
    "country": "អ៊ីរ៉ាក់"
  }, {
    "code": "IL",
    "country": "អ៊ីស្រាអែល"
  }, {
    "code": "IS",
    "country": "អ៊ីស្លង់"
  }, {
    "code": "UA",
    "country": "អ៊ុយក្រែន"
  }, {
    "code": "UY",
    "country": "អ៊ុយរូហ្គាយ"
  }, {
    "code": "AT",
    "country": "អូទ្រីស"
  }, {
    "code": "OM",
    "country": "អូម៉ង់"
  }, {
    "code": "UZ",
    "country": "អ៊ូសបេគីស្ថាន"
  }, {
    "code": "AU",
    "country": "អូស្ត្រាលី"
  }, {
    "code": "UG",
    "country": "អ៊ូហ្គង់ដា"
  }, {
    "code": "IE",
    "country": "អៀរឡង់"
  }, {
    "code": "EC",
    "country": "អេក្វាទ័រ"
  }, {
    "code": "ET",
    "country": "អេត្យូពី"
  }, {
    "code": "AE",
    "country": "អេមីរ៉ាតអារ៉ាប់រួម"
  }, {
    "code": "ER",
    "country": "អេរីត្រេ"
  }, {
    "code": "EE",
    "country": "អេស្តូនី"
  }, {
    "code": "ES",
    "country": "អេស្ប៉ាញ"
  }, {
    "code": "EG",
    "country": "អេហ្ស៊ីប"
  }, {
    "code": "SV",
    "country": "អែលសាល់វ៉ាឌ័រ"
  }, {
    "code": "IM",
    "country": "អែលអុហ្វមែន"
  }];
  _exports.default = _default;
});