define("huvepharma-aviapp-admin/base/enum/continents/en-km", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "អាហ្រ្វិក"
  }, {
    "code": "NA",
    "continent": "អាមេរិកខាងជើង"
  }, {
    "code": "OC",
    "continent": "មហាសមុទ្រ"
  }, {
    "code": "AN",
    "continent": "អង់តាក់ទិក"
  }, {
    "code": "AS",
    "continent": "អាស៊ី"
  }, {
    "code": "EU",
    "continent": "អឺរ៉ុប"
  }, {
    "code": "SA",
    "continent": "អាមេរិកខាងត្បូង"
  }];
  _exports.default = _default;
});