define("huvepharma-aviapp-admin/base/enum/countries/en-my", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "CA",
    "country": "ကနေဒါ"
  }, {
    "code": "KG",
    "country": "ကာဂျစ္စတန်"
  }, {
    "code": "KZ",
    "country": "ကာဇက်စတန်"
  }, {
    "code": "QA",
    "country": "ကာတာ"
  }, {
    "code": "BQ",
    "country": "ကာရစ်ဘီယံ နယ်သာလန်"
  }, {
    "code": "KW",
    "country": "ကူဝိတ်"
  }, {
    "code": "KY",
    "country": "ကေမန် ကျွန်းစု"
  }, {
    "code": "CC",
    "country": "ကိုကိုးကျွန်း"
  }, {
    "code": "KM",
    "country": "ကိုမိုရိုစ်"
  }, {
    "code": "CO",
    "country": "ကိုလံဘီယာ"
  }, {
    "code": "CR",
    "country": "ကို့စ်တာရီကာ"
  }, {
    "code": "CI",
    "country": "ကို့တ် ဒီဗွာ"
  }, {
    "code": "KE",
    "country": "ကင်ညာ"
  }, {
    "code": "CM",
    "country": "ကင်မရွန်း"
  }, {
    "code": "CV",
    "country": "ကိတ်ဗာဒီ"
  }, {
    "code": "KH",
    "country": "ကမ္ဘောဒီးယား"
  }, {
    "code": "CW",
    "country": "ကျူရေးကိုးစ်"
  }, {
    "code": "CU",
    "country": "ကျူးဘား"
  }, {
    "code": "CK",
    "country": "ကွတ် ကျွန်းစု"
  }, {
    "code": "CD",
    "country": "ကွန်ဂို"
  }, {
    "code": "CG",
    "country": "ကွန်ဂို-ဘရာဇာဗီးလ်"
  }, {
    "code": "HR",
    "country": "ခရိုအေးရှား"
  }, {
    "code": "CX",
    "country": "ခရစ်စမတ် ကျွန်း"
  }, {
    "code": "KI",
    "country": "ခီရီဘာတီ"
  }, {
    "code": "CL",
    "country": "ချီလီ"
  }, {
    "code": "CZ",
    "country": "ချက်ကီယား"
  }, {
    "code": "TD",
    "country": "ချဒ်"
  }, {
    "code": "GR",
    "country": "ဂရိ"
  }, {
    "code": "GD",
    "country": "ဂရီနေဒါ"
  }, {
    "code": "GL",
    "country": "ဂရင်းလန်း"
  }, {
    "code": "GH",
    "country": "ဂါနာ"
  }, {
    "code": "GA",
    "country": "ဂါဘွန်"
  }, {
    "code": "GN",
    "country": "ဂီနီ"
  }, {
    "code": "GW",
    "country": "ဂီနီ-ဘီစော"
  }, {
    "code": "GU",
    "country": "ဂူအမ်"
  }, {
    "code": "GY",
    "country": "ဂိုင်ယာနာ"
  }, {
    "code": "GM",
    "country": "ဂမ်ဘီရာ"
  }, {
    "code": "JP",
    "country": "ဂျပန်"
  }, {
    "code": "JM",
    "country": "ဂျမေကာ"
  }, {
    "code": "JE",
    "country": "ဂျာစီ"
  }, {
    "code": "DE",
    "country": "ဂျာမနီ"
  }, {
    "code": "GI",
    "country": "ဂျီဘရော်လ်တာ"
  }, {
    "code": "DJ",
    "country": "ဂျီဘူတီ"
  }, {
    "code": "GE",
    "country": "ဂျော်ဂျီယာ"
  }, {
    "code": "JO",
    "country": "ဂျော်ဒန်"
  }, {
    "code": "GT",
    "country": "ဂွါတီမာလာ"
  }, {
    "code": "GP",
    "country": "ဂွါဒီလု"
  }, {
    "code": "GG",
    "country": "ဂွန်းဇီ"
  }, {
    "code": "ES",
    "country": "စပိန်"
  }, {
    "code": "SJ",
    "country": "စဗိုလ်ဘတ်နှင့်ဂျန်မေရန်"
  }, {
    "code": "KN",
    "country": "စိန့်ကစ်နှင့်နီဗီစ်"
  }, {
    "code": "PM",
    "country": "စိန့်ပီအဲရ်နှင့် မီကွီလွန်"
  }, {
    "code": "VC",
    "country": "စိန့်ဗင်းဆင့်နှင့် ဂရိနေဒိုင်"
  }, {
    "code": "BL",
    "country": "စိန့်ဘာသယ်လ်မီ"
  }, {
    "code": "MF",
    "country": "စိန့်မာတင်"
  }, {
    "code": "LC",
    "country": "စိန့်လူစီယာ"
  }, {
    "code": "SH",
    "country": "စိန့်ဟယ်လယ်နာ"
  }, {
    "code": "SG",
    "country": "စင်္ကာပူ"
  }, {
    "code": "SX",
    "country": "စင့်မာတင်"
  }, {
    "code": "WS",
    "country": "ဆမိုးအား"
  }, {
    "code": "SI",
    "country": "ဆလိုဗေးနီးယား"
  }, {
    "code": "SK",
    "country": "ဆလိုဗက်ကီးယား"
  }, {
    "code": "RS",
    "country": "ဆားဘီးယား"
  }, {
    "code": "SN",
    "country": "ဆီနီဂေါ"
  }, {
    "code": "SL",
    "country": "ဆီယာရာ လီယွန်း"
  }, {
    "code": "SY",
    "country": "ဆီးရီးယား"
  }, {
    "code": "SD",
    "country": "ဆူဒန်"
  }, {
    "code": "SR",
    "country": "ဆူရာနမ်"
  }, {
    "code": "SC",
    "country": "ဆေးရှဲ"
  }, {
    "code": "SA",
    "country": "ဆော်ဒီအာရေးဘီးယား"
  }, {
    "code": "SB",
    "country": "ဆော်လမွန်ကျွန်းစု"
  }, {
    "code": "SO",
    "country": "ဆိုမာလီယာ"
  }, {
    "code": "CY",
    "country": "ဆိုက်ပရပ်စ်"
  }, {
    "code": "ST",
    "country": "ဆောင်တူမေးနှင့် ပရင်စီပီ"
  }, {
    "code": "SM",
    "country": "ဆန်မာရီနို"
  }, {
    "code": "SZ",
    "country": "ဆွာဇီလန်"
  }, {
    "code": "SE",
    "country": "ဆွီဒင်"
  }, {
    "code": "CH",
    "country": "ဆွစ်ဇာလန်"
  }, {
    "code": "ZW",
    "country": "ဇင်ဘာဘွေ"
  }, {
    "code": "ZM",
    "country": "ဇမ်ဘီယာ"
  }, {
    "code": "CN",
    "country": "တရုတ်"
  }, {
    "code": "TJ",
    "country": "တာဂျီကစ္စတန်"
  }, {
    "code": "TM",
    "country": "တာ့ခ်မင်နစ္စတန်"
  }, {
    "code": "TN",
    "country": "တူနီးရှား"
  }, {
    "code": "TV",
    "country": "တူဗားလူ"
  }, {
    "code": "TR",
    "country": "တူရကီ"
  }, {
    "code": "TK",
    "country": "တိုကလောင်"
  }, {
    "code": "TG",
    "country": "တိုဂို"
  }, {
    "code": "TC",
    "country": "တခ်စ်နှင့်ကာအီကိုစ်ကျွန်းစု"
  }, {
    "code": "GS",
    "country": "တောင် ဂျော်ဂျီယာ နှင့် တောင် ဆင်းဒဝစ်ဂျ် ကျွန်းစုများ"
  }, {
    "code": "SS",
    "country": "တောင် ဆူဒန်"
  }, {
    "code": "KR",
    "country": "တောင်ကိုရီးယား"
  }, {
    "code": "ZA",
    "country": "တောင်အာဖရိက"
  }, {
    "code": "TZ",
    "country": "တန်ဇန်းနီးယား"
  }, {
    "code": "TO",
    "country": "တွန်ဂါ"
  }, {
    "code": "TT",
    "country": "ထရီနီဒတ်နှင့် တိုဘက်ဂို"
  }, {
    "code": "TW",
    "country": "ထိုင်ဝမ်"
  }, {
    "code": "TH",
    "country": "ထိုင်း"
  }, {
    "code": "DM",
    "country": "ဒိုမီနီကာ"
  }, {
    "code": "DO",
    "country": "ဒိုမီနီကန်"
  }, {
    "code": "DK",
    "country": "ဒိန်းမတ်"
  }, {
    "code": "NA",
    "country": "နမီးဘီးယား"
  }, {
    "code": "NC",
    "country": "နယူး ကယ်လီဒိုနီးယား"
  }, {
    "code": "NZ",
    "country": "နယူးဇီလန်"
  }, {
    "code": "NI",
    "country": "နီကာရာဂွါ"
  }, {
    "code": "NP",
    "country": "နီပေါ"
  }, {
    "code": "NU",
    "country": "နီဥူအေ"
  }, {
    "code": "NF",
    "country": "နောဖုတ်ကျွန်း"
  }, {
    "code": "NR",
    "country": "နော်ရူး"
  }, {
    "code": "NO",
    "country": "နော်ဝေ"
  }, {
    "code": "NE",
    "country": "နိုင်ဂျာ"
  }, {
    "code": "NG",
    "country": "နိုင်ဂျီးရီးယား"
  }, {
    "code": "NL",
    "country": "နယ်သာလန်"
  }, {
    "code": "PA",
    "country": "ပနားမား"
  }, {
    "code": "PW",
    "country": "ပလာအို"
  }, {
    "code": "PK",
    "country": "ပါကစ္စတန်"
  }, {
    "code": "PG",
    "country": "ပါပူအာ နယူးဂီနီ"
  }, {
    "code": "PY",
    "country": "ပါရာဂွေး"
  }, {
    "code": "PS",
    "country": "ပါလက်စတိုင်း ပိုင်နက်"
  }, {
    "code": "PE",
    "country": "ပီရူး"
  }, {
    "code": "PT",
    "country": "ပေါ်တူဂီ"
  }, {
    "code": "PR",
    "country": "ပေါ်တိုရီကို"
  }, {
    "code": "PL",
    "country": "ပိုလန်"
  }, {
    "code": "PN",
    "country": "ပစ်တ်ကိန်းကျွန်းစု"
  }, {
    "code": "FR",
    "country": "ပြင်သစ်"
  }, {
    "code": "GF",
    "country": "ပြင်သစ် ဂီယာနာ"
  }, {
    "code": "TF",
    "country": "ပြင်သစ် တောင်ပိုင်း ပိုင်နက်များ"
  }, {
    "code": "PF",
    "country": "ပြင်သစ် ပေါ်လီနီးရှား"
  }, {
    "code": "FO",
    "country": "ဖာရိုး ကျွန်းစုများ"
  }, {
    "code": "PH",
    "country": "ဖိလစ်ပိုင်"
  }, {
    "code": "FJ",
    "country": "ဖီဂျီ"
  }, {
    "code": "FK",
    "country": "ဖော့ကလန် ကျွန်းစု"
  }, {
    "code": "FI",
    "country": "ဖင်လန်"
  }, {
    "code": "VU",
    "country": "ဗနွားတူ"
  }, {
    "code": "CF",
    "country": "ဗဟို အာဖရိက ပြည်ထောင်စု"
  }, {
    "code": "VA",
    "country": "ဗာတီကန်စီးတီး"
  }, {
    "code": "VN",
    "country": "ဗီယက်နမ်"
  }, {
    "code": "VE",
    "country": "ဗင်နီဇွဲလား"
  }, {
    "code": "VG",
    "country": "ဗြိတိသျှ ဗာဂျင်း ကျွန်းစု"
  }, {
    "code": "IO",
    "country": "ဗြိတိသျှပိုင် အိန္ဒိယသမုဒ္ဒရာကျွန်းများ"
  }, {
    "code": "BR",
    "country": "ဘရာဇီး"
  }, {
    "code": "BN",
    "country": "ဘရူနိုင်း"
  }, {
    "code": "BZ",
    "country": "ဘလိဇ်"
  }, {
    "code": "BS",
    "country": "ဘဟားမား"
  }, {
    "code": "BF",
    "country": "ဘာကီးနား ဖားဆို"
  }, {
    "code": "BB",
    "country": "ဘာဘေးဒိုးစ်"
  }, {
    "code": "BM",
    "country": "ဘာမြူဒါ"
  }, {
    "code": "BH",
    "country": "ဘာရိန်း"
  }, {
    "code": "BJ",
    "country": "ဘီနင်"
  }, {
    "code": "BY",
    "country": "ဘီလာရုစ်"
  }, {
    "code": "BT",
    "country": "ဘူတန်"
  }, {
    "code": "BV",
    "country": "ဘူဗက်ကျွန်း"
  }, {
    "code": "BI",
    "country": "ဘူရွန်ဒီ"
  }, {
    "code": "BG",
    "country": "ဘူလ်ဂေးရီးယား"
  }, {
    "code": "BA",
    "country": "ဘော့စနီးယားနှင့် ဟာဇီဂိုဗီနား"
  }, {
    "code": "BW",
    "country": "ဘော့ဆွာနာ"
  }, {
    "code": "BO",
    "country": "ဘိုလီးဗီးယား"
  }, {
    "code": "BD",
    "country": "ဘင်္ဂလားဒေ့ရှ်"
  }, {
    "code": "BE",
    "country": "ဘယ်လ်ဂျီယမ်"
  }, {
    "code": "MO",
    "country": "မကာအို (တရုတ်ပြည်)"
  }, {
    "code": "MG",
    "country": "မဒါဂတ်စကား"
  }, {
    "code": "MY",
    "country": "မလေးရှား"
  }, {
    "code": "MQ",
    "country": "မာတီနိခ်"
  }, {
    "code": "MH",
    "country": "မာရှယ် ကျွန်းစု"
  }, {
    "code": "MW",
    "country": "မာလာဝီ"
  }, {
    "code": "ML",
    "country": "မာလီ"
  }, {
    "code": "YT",
    "country": "မေယော့"
  }, {
    "code": "MU",
    "country": "မောရစ်ရှ"
  }, {
    "code": "MS",
    "country": "မောင့်စဲရက်"
  }, {
    "code": "MR",
    "country": "မော်ရီတေးနီးယား"
  }, {
    "code": "MA",
    "country": "မော်ရိုကို"
  }, {
    "code": "MV",
    "country": "မော်လ်ဒိုက်"
  }, {
    "code": "MZ",
    "country": "မိုဇမ်ဘစ်"
  }, {
    "code": "MC",
    "country": "မိုနာကို"
  }, {
    "code": "MX",
    "country": "မက်ကဆီကို"
  }, {
    "code": "FM",
    "country": "မိုင်ခရိုနီရှား"
  }, {
    "code": "IM",
    "country": "မန်ကျွန်း"
  }, {
    "code": "MT",
    "country": "မောလ်တာ"
  }, {
    "code": "MD",
    "country": "မောလ်ဒိုဗာ"
  }, {
    "code": "MK",
    "country": "မြောက် မက်ဆီဒိုးနီးယား"
  }, {
    "code": "KP",
    "country": "မြောက်ကိုရီးယား"
  }, {
    "code": "MP",
    "country": "မြောက်ပိုင်းမာရီအာနာကျွန်းစု"
  }, {
    "code": "MM",
    "country": "မြန်မာ"
  }, {
    "code": "MN",
    "country": "မွန်ဂိုးလီးယား"
  }, {
    "code": "ME",
    "country": "မွန်တီနိဂရိုး"
  }, {
    "code": "YE",
    "country": "ယီမင်"
  }, {
    "code": "UA",
    "country": "ယူကရိန်း"
  }, {
    "code": "UG",
    "country": "ယူဂန်းဒါး"
  }, {
    "code": "GB",
    "country": "ယူနိုက်တက်ကင်းဒမ်း"
  }, {
    "code": "UM",
    "country": "ယူနိုက်တက်စတိတ် ကျွန်းနိုင်ငံများ"
  }, {
    "code": "AE",
    "country": "ယူအေအီး"
  }, {
    "code": "VI",
    "country": "ယူအက်စ် ဗာဂျင်း ကျွန်းစု"
  }, {
    "code": "RW",
    "country": "ရဝန်ဒါ"
  }, {
    "code": "RE",
    "country": "ရီယူနီယန်"
  }, {
    "code": "RU",
    "country": "ရုရှား"
  }, {
    "code": "RO",
    "country": "ရိုမေးနီးယား"
  }, {
    "code": "LA",
    "country": "လာအို"
  }, {
    "code": "LS",
    "country": "လီဆိုသို"
  }, {
    "code": "LU",
    "country": "လူဇင်ဘတ်"
  }, {
    "code": "LB",
    "country": "လက်ဘနွန်"
  }, {
    "code": "LR",
    "country": "လိုက်ဘေးရီးယား"
  }, {
    "code": "LI",
    "country": "လစ်တန်စတိန်း"
  }, {
    "code": "LY",
    "country": "လစ်ဗျား"
  }, {
    "code": "LT",
    "country": "လစ်သူယေးနီးယား"
  }, {
    "code": "LV",
    "country": "လတ်ဗီးယား"
  }, {
    "code": "WF",
    "country": "ဝေါလစ်နှင့် ဖူကျူးနား"
  }, {
    "code": "LK",
    "country": "သီရိလင်္ကာ"
  }, {
    "code": "HT",
    "country": "ဟေတီ"
  }, {
    "code": "HK",
    "country": "ဟောင်ကောင် (တရုတ်ပြည်)"
  }, {
    "code": "HM",
    "country": "ဟတ်ကျွန်းနှင့်မက်ဒေါနယ်ကျွန်းစု"
  }, {
    "code": "HU",
    "country": "ဟန်ဂေရီ"
  }, {
    "code": "HN",
    "country": "ဟွန်ဒူးရပ်စ်"
  }, {
    "code": "AZ",
    "country": "အဇာဘိုင်ဂျန်"
  }, {
    "code": "EH",
    "country": "အနောက် ဆာဟာရ"
  }, {
    "code": "AS",
    "country": "အမေရိကန် ဆမိုးအား"
  }, {
    "code": "US",
    "country": "အမေရိကန် ပြည်ထောင်စု"
  }, {
    "code": "TL",
    "country": "အရှေ့တီမော"
  }, {
    "code": "AR",
    "country": "အာဂျင်တီးနား"
  }, {
    "code": "AF",
    "country": "အာဖဂန်နစ္စတန်"
  }, {
    "code": "AM",
    "country": "အာမေးနီးယား"
  }, {
    "code": "AW",
    "country": "အာရူးဗား"
  }, {
    "code": "AX",
    "country": "အာလန်ကျွန်း"
  }, {
    "code": "GQ",
    "country": "အီကွေတာ ဂီနီ"
  }, {
    "code": "EC",
    "country": "အီကွေဒေါ"
  }, {
    "code": "EG",
    "country": "အီဂျစ်"
  }, {
    "code": "IT",
    "country": "အီတလီ"
  }, {
    "code": "ER",
    "country": "အီရီထရီးယား"
  }, {
    "code": "IQ",
    "country": "အီရတ်"
  }, {
    "code": "IR",
    "country": "အီရန်"
  }, {
    "code": "ET",
    "country": "အီသီယိုးပီးယား"
  }, {
    "code": "UZ",
    "country": "ဥဇဘက်ကစ္စတန်"
  }, {
    "code": "UY",
    "country": "ဥရုဂွေး"
  }, {
    "code": "AT",
    "country": "ဩစတြီးယား"
  }, {
    "code": "AU",
    "country": "ဩစတြေးလျ"
  }, {
    "code": "OM",
    "country": "အိုမန်"
  }, {
    "code": "EE",
    "country": "အက်စတိုးနီးယား"
  }, {
    "code": "IS",
    "country": "အိုက်စလန်"
  }, {
    "code": "ID",
    "country": "အင်ဒိုနီးရှား"
  }, {
    "code": "IE",
    "country": "အိုင်ယာလန်"
  }, {
    "code": "IL",
    "country": "အစ္စရေး"
  }, {
    "code": "AI",
    "country": "အန်ဂီလာ"
  }, {
    "code": "AO",
    "country": "အန်ဂိုလာ"
  }, {
    "code": "AQ",
    "country": "အန်တာတိက"
  }, {
    "code": "AG",
    "country": "အန်တီဂွါနှင့် ဘာဘူဒါ"
  }, {
    "code": "AD",
    "country": "အန်ဒိုရာ"
  }, {
    "code": "IN",
    "country": "အိန္ဒိယ"
  }, {
    "code": "DZ",
    "country": "အယ်လ်ဂျီးရီးယား"
  }, {
    "code": "SV",
    "country": "အယ်လ်ဆာဗေးဒိုး"
  }, {
    "code": "AL",
    "country": "အယ်လ်ဘေးနီးယား"
  }];
  _exports.default = _default;
});