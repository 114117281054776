define("huvepharma-aviapp-admin/base/modules/scoring-modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var moduleTree = {
    modules: []
  };
  var external = {
    id: "external",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "external",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var external_footpadDermatitis = {
    id: "footpadDermatitis",
    abbreviation: "fpd",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2],
    range: [0, 200],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/footpad-dermatitis_0.jpg",
      "description": "modules_scoring_footpadDermatitis_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/external/footpad-dermatitis_1.jpg",
      "description": "modules_scoring_footpadDermatitis_score1_description"
    }, {
      "score": 2,
      "images": ["assets/module-images/external/footpad-dermatitis_2A.jpg", "assets/module-images/external/footpad-dermatitis_2B.jpg"],
      "description": "modules_scoring_footpadDermatitis_score2_description"
    }],
    name: "footpadDermatitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this.multipliers[key]) {
          meanValue += scoreCounts[key] * _this.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_footpadDermatitis);
  var external_trachea = {
    id: "trachea",
    abbreviation: "tra",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/trachea_0.jpg",
      "description": "modules_scoring_trachea_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/external/trachea_1.jpg",
      "description": "modules_scoring_trachea_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/external/trachea_2.jpg",
      "description": "modules_scoring_trachea_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/external/trachea_3.jpg",
      "description": "modules_scoring_trachea_score3_description"
    }],
    name: "trachea",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this2 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this2.multipliers[key]) {
          meanValue += scoreCounts[key] * _this2.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_trachea);
  var external_scratches = {
    id: "scratches",
    abbreviation: "scr",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2],
    range: [0, 200],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/external/scratches_1.jpg",
      "description": "modules_scoring_scratches_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/external/scratches_2.jpg",
      "description": "modules_scoring_scratches_score2_description"
    }],
    name: "scratches",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this3 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this3.multipliers[key]) {
          meanValue += scoreCounts[key] * _this3.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_scratches);
  var external_ammoniaBurns = {
    id: "ammoniaBurns",
    abbreviation: "amm",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/external/ammonia-burns_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "ammoniaBurns",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_ammoniaBurns);
  var external_mouthLesions = {
    id: "mouthLesions",
    abbreviation: "mou",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/mouth-lesions_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/external/mouth-lesions_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "mouthLesions",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_mouthLesions);
  var external_legColor = {
    id: "legColor",
    abbreviation: "lc",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/leg-color_0.jpg",
      "description": "modules_scoring_legColor_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/external/leg-color_1.jpg",
      "description": "modules_scoring_legColor_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/external/leg-color_2.jpg",
      "description": "modules_scoring_legColor_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/external/leg-color_3.jpg",
      "description": "modules_scoring_legColor_score3_description"
    }],
    name: "legColor",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this4 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this4.multipliers[key]) {
          meanValue += scoreCounts[key] * _this4.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_legColor);
  var external_hockBurn = {
    id: "hockBurn",
    abbreviation: "HOCKB",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2],
    range: [0, 200],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/hock-burns_0.png",
      "description": "modules_scoring_hockBurn_score0_description"
    }, {
      "score": 1,
      "images": ["assets/module-images/external/hock-burns_1_1.png", "assets/module-images/external/hock-burns_1_2.png"],
      "description": "modules_scoring_hockBurn_score1_description"
    }, {
      "score": 2,
      "description": "modules_scoring_hockBurn_score2_description"
    }],
    name: "hockBurn",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this5 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this5.multipliers[key]) {
          meanValue += scoreCounts[key] * _this5.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_hockBurn);
  var external_breastBlister = {
    id: "breastBlister",
    abbreviation: "BBL",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/breast-blister_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "description": "modules_scoring_breastBlister_score1_description"
    }],
    name: "breastBlister",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_breastBlister);
  var external_contactDermatitis = {
    id: "contactDermatitis",
    abbreviation: "cd",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/contact-dermatitis_0.jpg",
      "description": "modules_scoring_contactDermatitis_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/external/contact-dermatitis_1.jpg",
      "description": "modules_scoring_contactDermatitis_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/external/contact-dermatitis_2.jpg",
      "description": "modules_scoring_contactDermatitis_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/external/contact-dermatitis_3.jpg",
      "description": "modules_scoring_contactDermatitis_score3_description"
    }],
    name: "contactDermatitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this6 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this6.multipliers[key]) {
          meanValue += scoreCounts[key] * _this6.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_contactDermatitis);
  var external_feathering = {
    id: "feathering",
    abbreviation: "FEA",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2],
    range: [0, 200],
    info: [{
      "score": 0,
      "image": "assets/module-images/external/feathers_0.jpg",
      "description": "modules_scoring_feathering_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/external/feathers_1.jpg",
      "description": "modules_scoring_feathering_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/external/feathers_2.jpg",
      "description": "modules_scoring_feathering_score2_description"
    }],
    name: "feathering",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this7 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this7.multipliers[key]) {
          meanValue += scoreCounts[key] * _this7.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  external.modules.push(external_feathering);
  moduleTree.modules.push(external);
  var skeletal = {
    id: "skeletal",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "skeletal",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var skeletal_femoralHeadNecrosis = {
    id: "femoralHeadNecrosis",
    abbreviation: "fhn",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/skeletal/femoral-head_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/femoral-head_1.jpg",
      "description": "modules_scoring_femoralHeadNecrosis_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/skeletal/femoral-head_2.jpg",
      "description": "modules_scoring_femoralHeadNecrosis_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/skeletal/femoral-head_3.jpg",
      "description": "modules_scoring_femoralHeadNecrosis_score3_description"
    }],
    name: "femoralHeadNecrosis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this8 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this8.multipliers[key]) {
          meanValue += scoreCounts[key] * _this8.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_femoralHeadNecrosis);
  var skeletal_tibialDyschondroplasia = {
    id: "tibialDyschondroplasia",
    abbreviation: "tbd",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/skeletal/tibial-dyschondroplasia_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/tibial-dyschondroplasia_1.jpg",
      "description": "modules_scoring_tibialDyschondroplasia_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/skeletal/tibial-dyschondroplasia_2.jpg",
      "description": "modules_scoring_tibialDyschondroplasia_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/skeletal/tibial-dyschondroplasia_3.jpg",
      "description": "modules_scoring_tibialDyschondroplasia_score3_description"
    }],
    name: "tibialDyschondroplasia",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this9 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this9.multipliers[key]) {
          meanValue += scoreCounts[key] * _this9.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_tibialDyschondroplasia);
  var skeletal_detachedCartilage = {
    id: "detachedCartilage",
    abbreviation: "car",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/skeletal/detached-cartilage_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/detached-cartilage_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "detachedCartilage",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_detachedCartilage);
  var skeletal_enlargedGrowthPlate = {
    id: "enlargedGrowthPlate",
    abbreviation: "egp",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/skeletal/enlarged-growth-plate_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/enlarged-growth-plate_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "enlargedGrowthPlate",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_enlargedGrowthPlate);
  var skeletal_swollenHocks = {
    id: "swollenHocks",
    abbreviation: "hoc",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "description": "modules_scoring_label_presence"
    }],
    name: "swollenHocks",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_swollenHocks);
  var skeletal_rickets = {
    id: "rickets",
    abbreviation: "ric",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/rickets_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "rickets",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_rickets);
  var skeletal_softBrittleBones = {
    id: "softBrittleBones",
    abbreviation: "bri",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "softBrittleBones",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_softBrittleBones);
  var skeletal_synovitis = {
    id: "synovitis",
    abbreviation: "syn",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/skeletal/synovitis_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/synovitis_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "synovitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_synovitis);
  var skeletal_tendonititsTenosynovitis = {
    id: "tendonititsTenosynovitis",
    abbreviation: "ten",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2],
    range: [0, 200],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/tendinitis_1.jpg",
      "description": "modules_scoring_tendonititsTenosynovitis_score1_description"
    }, {
      "score": 2,
      "description": "modules_scoring_tendonititsTenosynovitis_score2_description"
    }],
    name: "tendonititsTenosynovitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this10 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this10.multipliers[key]) {
          meanValue += scoreCounts[key] * _this10.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_tendonititsTenosynovitis);
  var skeletal_osteomyelitis = {
    id: "osteomyelitis",
    abbreviation: "ost",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/skeletal/osteomyelitis_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "osteomyelitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  skeletal.modules.push(skeletal_osteomyelitis);
  moduleTree.modules.push(skeletal);
  var gaitScore = {
    id: "gaitScore",
    abbreviation: "gait",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }, {
      "value": 5
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3, 4, 5],
    range: [0, 500],
    info: [{
      "score": 0,
      "description": "modules_scoring_gaitScore_score0_description"
    }, {
      "score": 1,
      "description": "modules_scoring_gaitScore_score1_description"
    }, {
      "score": 2,
      "description": "modules_scoring_gaitScore_score2_description"
    }, {
      "score": 3,
      "description": "modules_scoring_gaitScore_score3_description"
    }, {
      "score": 4,
      "description": "modules_scoring_gaitScore_score4_description"
    }, {
      "score": 5,
      "description": "modules_scoring_gaitScore_score5_description"
    }],
    name: "gaitScore",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this11 = this;

      // % met Score 0 x 0, plus % met Score 1 x 0,5, plus % met Score 2 x2, plus % met Score 3, plus % Score 4x4, plus % met score 5x5
      var nofScores = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(nofScores).forEach(function (key) {
        if (_this11.multipliers[key]) {
          meanValue += nofScores[key] * _this11.multipliers[key];
        }
      });
      meanValue *= 10;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  moduleTree.modules.push(gaitScore);
  var immuneSystem = {
    id: "immuneSystem",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "immuneSystem",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var immuneSystem_thymus = {
    id: "thymus",
    abbreviation: "thy",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/immune-system/thymus_0.jpg",
      "description": "modules_scoring_thymus_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/immune-system/thymus_1.jpg",
      "description": "modules_scoring_thymus_score1_description"
    }],
    name: "thymus",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  immuneSystem.modules.push(immuneSystem_thymus);
  var immuneSystem_bursaMeter = {
    id: "bursaMeter",
    abbreviation: "bum",
    dataset: [{
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }, {
      "value": 5
    }, {
      "value": 6
    }, {
      "value": 7
    }, {
      "value": 8
    }],
    isScorable: true,
    multipliers: [],
    range: [1, 8],
    info: [{
      "score": "1-8",
      "description": "modules_scoring_bursaMeter_score1score8_description"
    }],
    name: "bursaMeter",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var filteredScores = scores.filter(function (value) {
        return value != null;
      });
      return filteredScores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (filteredScores.length === 0 ? 1 : filteredScores.length);
    },
    modules: []
  };
  immuneSystem.modules.push(immuneSystem_bursaMeter);
  var immuneSystem_bursaSize = {
    id: "bursaSize",
    abbreviation: "bus",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "description": "modules_scoring_bursaSize_score0_description"
    }, {
      "score": 1,
      "description": "modules_scoring_bursaSize_score1_description"
    }, {
      "score": 2,
      "description": "modules_scoring_bursaSize_score2_description"
    }, {
      "score": 3,
      "description": "modules_scoring_bursaSize_score3_description"
    }],
    name: "bursaSize",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this12 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this12.multipliers[key]) {
          meanValue += scoreCounts[key] * _this12.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  immuneSystem.modules.push(immuneSystem_bursaSize);
  var immuneSystem_bursalDamage = {
    id: "bursalDamage",
    abbreviation: "bud",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/immune-system/bursal-damage_0A.jpg",
      "description": "modules_scoring_bursalDamage_score0_description"
    }, {
      "score": 0,
      "image": "assets/module-images/immune-system/bursal-damage_0B.jpg"
    }, {
      "score": 1,
      "image": "assets/module-images/immune-system/bursal-damage_1.jpg",
      "description": "modules_scoring_bursalDamage_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/immune-system/bursal-damage_2.jpg",
      "description": "modules_scoring_bursalDamage_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/immune-system/bursal-damage_3.jpg",
      "description": "modules_scoring_bursalDamage_score3_description"
    }],
    name: "bursalDamage",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this13 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this13.multipliers[key]) {
          meanValue += scoreCounts[key] * _this13.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  immuneSystem.modules.push(immuneSystem_bursalDamage);
  moduleTree.modules.push(immuneSystem);
  var internal = {
    id: "internal",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "internal",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var internal_airsacs = {
    id: "airsacs",
    abbreviation: "air",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3, 4],
    range: [0, 400],
    info: [{
      "score": 0,
      "image": "assets/module-images/internal/airsacs_0.jpg",
      "description": "modules_scoring_airsacs_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/internal/airsacs_1.jpg",
      "description": "modules_scoring_airsacs_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/internal/airsacs_2.jpg",
      "description": "modules_scoring_airsacs_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/internal/airsacs_3.jpg",
      "description": "modules_scoring_airsacs_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/internal/airsacs_4.jpg",
      "description": "modules_scoring_airsacs_score4_description"
    }],
    name: "airsacs",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this14 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this14.multipliers[key]) {
          meanValue += scoreCounts[key] * _this14.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_airsacs);
  var internal_ascites = {
    id: "ascites",
    abbreviation: "asc",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/internal/ascites_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "ascites",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_ascites);
  var internal_cardiovascular = {
    id: "cardiovascular",
    abbreviation: "cav",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/internal/cardiovascular_0.jpg",
      "description": "modules_scoring_cardiovascular_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/internal/cardiovascular_1.jpg",
      "description": "modules_scoring_cardiovascular_score1_description"
    }],
    name: "cardiovascular",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_cardiovascular);
  var internal_aspergillosis = {
    id: "aspergillosis",
    abbreviation: "asp",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/internal/aspergillosis_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/internal/aspergillosis_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "aspergillosis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_aspergillosis);
  var internal_sepsis = {
    id: "sepsis",
    abbreviation: "sep",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "description": "modules_scoring_label_presence"
    }],
    name: "sepsis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_sepsis);
  var internal_infectiousProcess = {
    id: "infectiousProcess",
    abbreviation: "ifp",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "images": ["assets/module-images/external/infectious-process_1A.jpg", "assets/module-images/external/infectious-process_1B.jpg"],
      "description": "modules_scoring_label_presence"
    }],
    name: "infectiousProcess",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  internal.modules.push(internal_infectiousProcess);
  moduleTree.modules.push(internal);
  var myopathies = {
    id: "myopathies",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "myopathies",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var myopathies_whiteStriping = {
    id: "whiteStriping",
    abbreviation: "whi",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/myopathies/white-striping_0.jpg",
      "description": "modules_scoring_whiteStriping_score0_description"
    }, {
      "score": 1,
      "images": ["assets/module-images/myopathies/white-striping_1A.jpg", "assets/module-images/myopathies/white-striping_1B.jpg"],
      "description": "modules_scoring_whiteStriping_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/myopathies/white-striping_2.jpg",
      "description": "modules_scoring_whiteStriping_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/myopathies/white-striping_3.jpg",
      "description": "modules_scoring_whiteStriping_score3_description"
    }],
    name: "whiteStriping",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this15 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this15.multipliers[key]) {
          meanValue += scoreCounts[key] * _this15.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  myopathies.modules.push(myopathies_whiteStriping);
  var myopathies_woodenBreast = {
    id: "woodenBreast",
    abbreviation: "wb",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "description": "modules_scoring_woodenBreast_score0_description",
      "images": ["assets/module-images/myopathies/wooden-breast_0A.jpg", "assets/module-images/myopathies/wooden-breast_0B.jpg"]
    }, {
      "score": 1,
      "description": "modules_scoring_woodenBreast_score1_description",
      "image": "assets/module-images/myopathies/wooden-breast_1.jpg"
    }, {
      "score": 2,
      "description": "modules_scoring_woodenBreast_score2_description",
      "image": "assets/module-images/myopathies/wooden-breast_2.jpg"
    }, {
      "score": 3,
      "description": "modules_scoring_woodenBreast_score3_description",
      "images": ["assets/module-images/myopathies/wooden-breast_3A.jpg", "assets/module-images/myopathies/wooden-breast_3B.jpg"]
    }],
    name: "woodenBreast",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this16 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this16.multipliers[key]) {
          meanValue += scoreCounts[key] * _this16.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  myopathies.modules.push(myopathies_woodenBreast);
  moduleTree.modules.push(myopathies);
  var intestinalTract = {
    id: "intestinalTract",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "intestinalTract",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var intestinalTract_proventriculitis = {
    id: "proventriculitis",
    abbreviation: "pro",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/proventriculus_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/proventriculus_1.jpg",
      "description": "modules_scoring_proventriculitis_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/proventriculus_2.jpg",
      "description": "modules_scoring_proventriculitis_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/proventriculus_3.jpg",
      "description": "modules_scoring_proventriculitis_score3_description"
    }],
    name: "proventriculitis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this17 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this17.multipliers[key]) {
          meanValue += scoreCounts[key] * _this17.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_proventriculitis);
  var intestinalTract_gizzardErosions = {
    id: "gizzardErosions",
    abbreviation: "giz",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/gizzard-erosion_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/gizzard-erosion_1.jpg",
      "description": "modules_scoring_gizzardErosions_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/gizzard-erosion_2.jpg",
      "description": "modules_scoring_gizzardErosions_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/gizzard-erosion_3.jpg"
    }],
    name: "gizzardErosions",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this18 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this18.multipliers[key]) {
          meanValue += scoreCounts[key] * _this18.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_gizzardErosions);
  var intestinalTract_litterEater = {
    id: "litterEater",
    abbreviation: "lie",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_litterEater_score0_description"
    }, {
      "score": 1,
      "images": ["assets/module-images/intestinal/litter-eater_1A.jpg", "assets/module-images/intestinal/litter-eater_1B.jpg"],
      "description": "modules_scoring_litterEater_score1_description"
    }],
    name: "litterEater",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_litterEater);
  var intestinalTract_retainedYolk = {
    id: "retainedYolk",
    abbreviation: "rey",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/retained-yolk_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/retained-yolk_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "retainedYolk",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_retainedYolk);
  var intestinalTract_intestinalGas = {
    id: "intestinalGas",
    abbreviation: "ig",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal-tract/intestinal-gas_0.jpg",
      "description": "modules_scoring_intestinalGas_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal-tract/intestinal-gas_1.jpg",
      "description": "modules_scoring_intestinalGas_score1_description"
    }],
    name: "intestinalGas",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_intestinalGas);
  var intestinalTract_caecalContentWatery = {
    id: "caecalContentWatery",
    abbreviation: "caew",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal-tract/caecal-content-watery_0.jpg",
      "description": "modules_scoring_caecalContentWatery_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal-tract/caecal-content-watery_1.jpg",
      "description": "modules_scoring_caecalContentWatery_score1_description"
    }],
    name: "caecalContentWatery",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_caecalContentWatery);
  var intestinalTract_caecalContentFoamy = {
    id: "caecalContentFoamy",
    abbreviation: "caef",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal-tract/caecal-content-foamy_0.jpg",
      "description": "modules_scoring_caecalContentFoamy_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal-tract/caecal-content-foamy_1.jpg",
      "description": "modules_scoring_caecalContentFoamy_score1_description"
    }],
    name: "caecalContentFoamy",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_caecalContentFoamy);
  var intestinalTract_caecalScoringWall = {
    id: "caecalScoringWall",
    abbreviation: "cw",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal-tract/caecal-wall_0.jpg",
      "description": "modules_scoring_caecalScoringWall_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal-tract/caecal-wall_1.jpg",
      "description": "modules_scoring_caecalScoringWall_score1_description"
    }],
    name: "caecalScoringWall",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalTract.modules.push(intestinalTract_caecalScoringWall);
  moduleTree.modules.push(intestinalTract);
  var coccidiosis = {
    id: "coccidiosis",
    abbreviation: "coc",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 12],
    info: [],
    name: "coccidiosis",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    calculateTMLS: function calculateTMLS(data) {
      if (!data.eimeriaAcervulina || !data.eimeriaTenella || !data.eimeriaMaxima) {
        return null;
      }

      var total = data.eimeriaAcervulina.values.reduce(function (sum, value) {
        return sum + value;
      }, 0) + data.eimeriaTenella.values.reduce(function (sum, value) {
        return sum + value;
      }, 0) + data.eimeriaMaxima.values.reduce(function (sum, value) {
        return sum + value;
      }, 0);
      var chickens = data.eimeriaAcervulina.values.length;
      return chickens > 0 ? total / chickens : null;
    },
    calculateTMLSPercentage: function calculateTMLSPercentage(data) {
      if (!data.eimeriaAcervulina || !data.eimeriaTenella || !data.eimeriaMaxima) {
        return null;
      }

      var chickens = data.eimeriaAcervulina.values.length;
      var total = 0;

      for (var i = 0; i < chickens; i++) {
        if (data.eimeriaAcervulina.values[i] !== 0 || data.eimeriaTenella.values[i] !== 0 || data.eimeriaMaxima.values[i] !== 0) {
          total++;
        }
      }

      return chickens > 0 ? total / chickens * 100 : null;
    },
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      // sum of scores E. acervulina + E.maxima + E. tenella (but NOT E. maxima MICRO)
      var meanValue = scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  var coccidiosis_eimeriaAcervulina = {
    id: "eimeriaAcervulina",
    abbreviation: "e.ace",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/e-acervulina_0.jpg",
      "description": "modules_scoring_eimeriaAcervulina_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/e-acervulina_1.jpg",
      "description": "modules_scoring_eimeriaAcervulina_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-acervulina_2.jpg",
      "description": "modules_scoring_eimeriaAcervulina_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/e-acervulina_3.jpg",
      "description": "modules_scoring_eimeriaAcervulina_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-acervulina_4.jpg",
      "description": "modules_scoring_eimeriaAcervulina_score4_description"
    }],
    name: "eimeriaAcervulina",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaAcervulina);
  var coccidiosis_eimeriaMaxima = {
    id: "eimeriaMaxima",
    abbreviation: "e.max",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/e-maxima_0.jpg",
      "description": "modules_scoring_eimeriaMaxima_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/e-maxima_1.jpg",
      "description": "modules_scoring_eimeriaMaxima_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-maxima_2.jpg",
      "description": "modules_scoring_eimeriaMaxima_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/e-maxima_3.jpg",
      "description": "modules_scoring_eimeriaMaxima_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-maxima_4.jpg",
      "description": "modules_scoring_eimeriaMaxima_score4_description"
    }],
    name: "eimeriaMaxima",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaMaxima);
  var coccidiosis_eimeriaTenella = {
    id: "eimeriaTenella",
    abbreviation: "e.ten",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 1,
      "image": "assets/module-images/intestinal/e-tenella_1.jpg",
      "description": "modules_scoring_eimeriaTenella_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-tenella_2.jpg",
      "description": "modules_scoring_eimeriaTenella_score2_description"
    }, {
      "score": 3,
      "images": ["assets/module-images/intestinal/e-tenella_3A.jpg", "assets/module-images/intestinal/e-tenella_3B.jpg"],
      "description": "modules_scoring_eimeriaTenella_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-tenella_4.jpg",
      "description": "modules_scoring_eimeriaTenella_score4_description"
    }],
    name: "eimeriaTenella",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaTenella);
  var coccidiosis_eimeriaMaximaMicro = {
    id: "eimeriaMaximaMicro",
    abbreviation: "e.mic",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 0,
      "description": "modules_scoring_eimeriaMaximaMicro_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/e-maxima_micro_1.jpg",
      "description": "modules_scoring_eimeriaMaximaMicro_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-maxima_micro_2.jpg",
      "description": "modules_scoring_eimeriaMaximaMicro_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/e-maxima_micro_3.jpg",
      "description": "modules_scoring_eimeriaMaximaMicro_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-maxima_micro_4.jpg",
      "description": "modules_scoring_eimeriaMaximaMicro_score4_description"
    }],
    name: "eimeriaMaximaMicro",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaMaximaMicro);
  var coccidiosis_eimeriaNecatrix = {
    id: "eimeriaNecatrix",
    abbreviation: "e.np",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/e-necatrix_0.jpg",
      "description": "modules_scoring_eimerianecatrix_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/e-necatrix_1.jpg",
      "description": "modules_scoring_eimerianecatrix_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-necatrix_2.jpg",
      "description": "modules_scoring_eimerianecatrix_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/e-necatrix_3.jpg",
      "description": "modules_scoring_eimerianecatrix_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-necatrix_4.jpg",
      "description": "modules_scoring_eimerianecatrix_score4_description"
    }],
    name: "eimeriaNecatrix",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaNecatrix);
  var coccidiosis_eimeriaBrunetti = {
    id: "eimeriaBrunetti",
    abbreviation: "e.bru",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }, {
      "value": 4
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 4],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/e-brunetti_0.jpg",
      "description": "modules_scoring_eimeriabrunetti_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/e-brunetti_1.jpg",
      "description": "modules_scoring_eimeriabrunetti_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/e-brunetti_2.jpg",
      "description": "modules_scoring_eimeriabrunetti_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/e-brunetti_3.jpg",
      "description": "modules_scoring_eimeriabrunetti_score3_description"
    }, {
      "score": 4,
      "image": "assets/module-images/intestinal/e-brunetti_4.jpg",
      "description": "modules_scoring_eimeriabrunetti_score4_description"
    }],
    name: "eimeriaBrunetti",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_eimeriaBrunetti);
  var coccidiosis_coccidiosisGrouped = {
    id: "coccidiosisGrouped",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 4],
    info: [],
    name: "coccidiosisGrouped",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: true,
    isBigScoring: false,
    allowEmpty: false,
    isChartOnly: true,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_coccidiosisGrouped);
  var coccidiosis_coccidiosisGroupedIndividual = {
    id: "coccidiosisGroupedIndividual",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 4],
    info: [],
    name: "coccidiosisGroupedIndividual",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: true,
    isBigScoring: false,
    allowEmpty: false,
    isChartOnly: true,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (sum, score) {
        return sum + score;
      }, 0) / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  coccidiosis.modules.push(coccidiosis_coccidiosisGroupedIndividual);
  moduleTree.modules.push(coccidiosis);
  var intestinalHealth = {
    id: "intestinalHealth",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "intestinalHealth",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var intestinalHealth_parasites = {
    id: "parasites",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "parasites",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var intestinalHealth_parasites_darklingBeetles = {
    id: "darklingBeetles",
    abbreviation: "bee",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/darkling_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "darklingBeetles",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_parasites.modules.push(intestinalHealth_parasites_darklingBeetles);
  var intestinalHealth_parasites_tapeworms = {
    id: "tapeworms",
    abbreviation: "tap",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/tapeworms_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "tapeworms",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_parasites.modules.push(intestinalHealth_parasites_tapeworms);
  var intestinalHealth_parasites_roundworms = {
    id: "roundworms",
    abbreviation: "rou",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/roundworms_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "roundworms",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_parasites.modules.push(intestinalHealth_parasites_roundworms);
  intestinalHealth.modules.push(intestinalHealth_parasites);
  var intestinalHealth_intestinalCondition = {
    id: "intestinalCondition",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "intestinalCondition",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var intestinalHealth_intestinalCondition_feedPassage = {
    id: "feedPassage",
    abbreviation: "fee",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/FeedPassage_Score0.jpg",
      "description": "modules_scoring_feedPassage_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/FeedPassage_Score1.jpg",
      "description": "modules_scoring_feedPassage_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/FeedPassage_Score2.jpg",
      "description": "modules_scoring_feedPassage_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/FeedPassage_Score3.jpg",
      "description": "modules_scoring_feedPassage_score3_description"
    }],
    name: "feedPassage",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this19 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this19.multipliers[key]) {
          meanValue += scoreCounts[key] * _this19.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_feedPassage);
  var intestinalHealth_intestinalCondition_hyperemia = {
    id: "hyperemia",
    abbreviation: "hyp",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }, {
      "value": 2
    }, {
      "value": 3
    }],
    isScorable: true,
    multipliers: [0, 0.5, 2, 3],
    range: [0, 300],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/hyperemia_0.jpg",
      "description": "modules_scoring_hyperemia_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/hyperemia_1.jpg",
      "description": "modules_scoring_hyperemia_score1_description"
    }, {
      "score": 2,
      "image": "assets/module-images/intestinal/hyperemia_2.jpg",
      "description": "modules_scoring_hyperemia_score2_description"
    }, {
      "score": 3,
      "image": "assets/module-images/intestinal/hyperemia_3.jpg",
      "description": "modules_scoring_hyperemia_score3_description"
    }],
    name: "hyperemia",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var _this20 = this;

      var scoreCounts = Object.assign(this.getDataset(), scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {}));
      var meanValue = 0;
      Object.keys(scoreCounts).forEach(function (key) {
        if (_this20.multipliers[key]) {
          meanValue += scoreCounts[key] * _this20.multipliers[key];
        }
      });
      meanValue *= 100 / scores.length;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_hyperemia);
  var intestinalHealth_intestinalCondition_cellularSloughing = {
    id: "cellularSloughing",
    abbreviation: "ces",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/cellular-sloughing_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/cellular-sloughing_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "cellularSloughing",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_cellularSloughing);
  var intestinalHealth_intestinalCondition_excessiveIntestinalMucus = {
    id: "excessiveIntestinalMucus",
    abbreviation: "eim",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/excessive-mucus_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/excessive-mucus_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "excessiveIntestinalMucus",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_excessiveIntestinalMucus);
  var intestinalHealth_intestinalCondition_intestinalTone = {
    id: "intestinalTone",
    abbreviation: "ton",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/intestinal-tone_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/intestinal-tone_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "intestinalTone",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_intestinalTone);
  var intestinalHealth_intestinalCondition_thinIntestines = {
    id: "thinIntestines",
    abbreviation: "thn",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/thin-intestines_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/thin-intestines_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "thinIntestines",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_thinIntestines);
  var intestinalHealth_intestinalCondition_thickIntestines = {
    id: "thickIntestines",
    abbreviation: "tck",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/thick-intestines_0.jpg",
      "description": "modules_scoring_label_normalCondition"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/thick-intestines_1.jpg",
      "description": "modules_scoring_label_abnormalCondition"
    }],
    name: "thickIntestines",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_thickIntestines);
  var intestinalHealth_intestinalCondition_excessiveIntestinalFluid = {
    id: "excessiveIntestinalFluid",
    abbreviation: "eif",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/excessive-fluid_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/excessive-fluid_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "excessiveIntestinalFluid",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_excessiveIntestinalFluid);
  var intestinalHealth_intestinalCondition_intestinalHemorrhage = {
    id: "intestinalHemorrhage",
    abbreviation: "hae",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "description": "modules_scoring_label_presence"
    }],
    name: "intestinalHemorrhage",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_intestinalHemorrhage);
  var intestinalHealth_intestinalCondition_necroticEnteritis = {
    id: "necroticEnteritis",
    abbreviation: "nec",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/necrotic-enteritis_0.jpg",
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/necrotic-enteritis_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "necroticEnteritis",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_necroticEnteritis);
  var intestinalHealth_intestinalCondition_excessiveBile = {
    id: "excessiveBile",
    abbreviation: "bil",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    info: [{
      "score": 0,
      "description": "modules_scoring_label_absence"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/excessive-bile_1.jpg",
      "description": "modules_scoring_label_presence"
    }],
    name: "excessiveBile",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var scoreCounts = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      var meanValue = scoreCounts[1] ? scoreCounts[1] * 100 / scores.length : 0;
      var max = this.range.max && typeof this.range.max !== 'function' ? this.range.max : this.range[1];
      return meanValue < max ? meanValue : max;
    },
    modules: []
  };
  intestinalHealth_intestinalCondition.modules.push(intestinalHealth_intestinalCondition_excessiveBile);
  intestinalHealth.modules.push(intestinalHealth_intestinalCondition);
  moduleTree.modules.push(intestinalHealth);
  var dysbacteriosis = {
    id: "dysbacteriosis",
    abbreviation: "dys",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 4],
    info: [],
    name: "dysbacteriosis",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    calculatePercentage: function calculatePercentage(data, modules) {
      modules = modules || dysModules; // Only calculate Dys. if all modules are present

      var availableDysbacteriosisModules = Object.keys(data || {}).filter(function (d) {
        return modules.includes(d);
      });

      if (availableDysbacteriosisModules.length !== modules.length) {
        return null;
      } // set number of chicken that have been scored


      if (!data[modules[0]]) return null;
      var numberOfChicken = data[modules[0]].values.length; // check for all chicken whether they were scored something other than 0
      // for at least one of the dysbacteriosis modules

      var totalAffected = 0;

      var _loop = function _loop(i) {
        var isAffected = false;
        modules.forEach(function (module) {
          if (data[module].values[i] !== 0) {
            isAffected = true;
          }
        });
        if (isAffected) totalAffected++;
      };

      for (var i = 0; i < numberOfChicken; i++) {
        _loop(i);
      }

      return totalAffected / numberOfChicken * 100;
    },
    calculateTotal: function calculateTotal(data, modules) {
      modules = modules || dysModules; // Only calculate Dys. if all modules are present

      var availableDysbacteriosisModules = Object.keys(data || {}).filter(function (d) {
        return modules.includes(d);
      });

      if (availableDysbacteriosisModules.length !== modules.length) {
        return null;
      }

      var total = 0;
      var length = 0;
      Object.keys(data || {}).forEach(function (name) {
        var module = data[name];

        if (modules.indexOf(name) > -1) {
          total += module.values.reduce(function (sum, value) {
            return sum + value;
          }, 0);
          length = module.values.length;
        }
      });
      return length > 0 ? total / 2.5 / length : null;
    },
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var meanValue = scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / scores.length * 4;
      return meanValue < options.range[1] ? meanValue : options.range[1];
    },
    modules: []
  };
  var dysbacteriosis_gutBallooning = {
    id: "gutBallooning",
    abbreviation: "gut",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/gut-ballooning_0.jpg",
      "description": "modules_scoring_gutBallooning_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/gut-ballooning_1.jpg",
      "description": "modules_scoring_gutBallooning_score1_description"
    }],
    name: "gutBallooning",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_gutBallooning);
  var dysbacteriosis_undigestedFeed = {
    id: "undigestedFeed",
    abbreviation: "undis",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/undigested_0.jpg",
      "description": "modules_scoring_undigestedFeed_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/undigested_1.jpg",
      "description": "modules_scoring_undigestedFeed_score1_description"
    }],
    name: "undigestedFeed",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_undigestedFeed);
  var dysbacteriosis_cranialInflammationBloodVesselsDilated = {
    id: "cranialInflammationBloodVesselsDilated",
    abbreviation: "cra.inf",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/inflammation_0.jpg",
      "description": "modules_scoring_cranialInflammationBloodVesselsDilated_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/inflammation_1.jpg",
      "description": "modules_scoring_cranialInflammationBloodVesselsDilated_score1_description"
    }],
    name: "cranialInflammationBloodVesselsDilated",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_cranialInflammationBloodVesselsDilated);
  var dysbacteriosis_cranialFlaccid = {
    id: "cranialFlaccid",
    abbreviation: "cra.fla",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/flaccid_0.jpg",
      "description": "modules_scoring_cranialFlaccid_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/flaccid_1.jpg",
      "description": "modules_scoring_cranialFlaccid_score1_description"
    }],
    name: "cranialFlaccid",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_cranialFlaccid);
  var dysbacteriosis_cranialAbnormalContents = {
    id: "cranialAbnormalContents",
    abbreviation: "cra.abn",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/abnormal_0.jpg",
      "description": "modules_scoring_cranialAbnormalContents_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/abnormal_1.jpg",
      "description": "modules_scoring_cranialAbnormalContents_score1_description"
    }],
    name: "cranialAbnormalContents",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_cranialAbnormalContents);
  var dysbacteriosis_cranialThicknessFlaccidTranslucencyFragility = {
    id: "cranialThicknessFlaccidTranslucencyFragility",
    abbreviation: "cra.thi",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/thickness_0.jpg",
      "description": "modules_scoring_cranialThicknessFlaccidTranslucencyFragility_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/thickness_1.jpg",
      "description": "modules_scoring_cranialThicknessFlaccidTranslucencyFragility_score1_description"
    }],
    name: "cranialThicknessFlaccidTranslucencyFragility",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_cranialThicknessFlaccidTranslucencyFragility);
  var dysbacteriosis_caudalInflammationBloodVesselsDilated = {
    id: "caudalInflammationBloodVesselsDilated",
    abbreviation: "cau.inf",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/inflammation_0.jpg",
      "description": "modules_scoring_caudalInflammationBloodVesselsDilated_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/inflammation_1.jpg",
      "description": "modules_scoring_caudalInflammationBloodVesselsDilated_score1_description"
    }],
    name: "caudalInflammationBloodVesselsDilated",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_caudalInflammationBloodVesselsDilated);
  var dysbacteriosis_caudalFlaccid = {
    id: "caudalFlaccid",
    abbreviation: "cau.fla",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/flaccid_0.jpg",
      "description": "modules_scoring_caudalFlaccid_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/flaccid_1.jpg",
      "description": "modules_scoring_caudalFlaccid_score1_description"
    }],
    name: "caudalFlaccid",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_caudalFlaccid);
  var dysbacteriosis_caudalAbnormalContents = {
    id: "caudalAbnormalContents",
    abbreviation: "cau.abn",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/abnormal_0.jpg",
      "description": "modules_scoring_caudalAbnormalContents_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/abnormal_1.jpg",
      "description": "modules_scoring_caudalAbnormalContents_score1_description"
    }],
    name: "caudalAbnormalContents",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_caudalAbnormalContents);
  var dysbacteriosis_caudalThicknessFlaccidTranslucencyFragility = {
    id: "caudalThicknessFlaccidTranslucencyFragility",
    abbreviation: "cau.thi",
    dataset: [{
      "value": 0
    }, {
      "value": 1
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 1],
    info: [{
      "score": 0,
      "image": "assets/module-images/intestinal/thickness_0.jpg",
      "description": "modules_scoring_caudalThicknessFlaccidTranslucencyFragility_score0_description"
    }, {
      "score": 1,
      "image": "assets/module-images/intestinal/thickness_1.jpg",
      "description": "modules_scoring_caudalThicknessFlaccidTranslucencyFragility_score1_description"
    }],
    name: "caudalThicknessFlaccidTranslucencyFragility",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  dysbacteriosis.modules.push(dysbacteriosis_caudalThicknessFlaccidTranslucencyFragility);
  moduleTree.modules.push(dysbacteriosis);
  var sex = {
    id: "sex",
    abbreviation: "sex",
    dataset: [{
      "value": "m"
    }, {
      "value": "f"
    }],
    isScorable: true,
    multipliers: [],
    range: [0, 100],
    overrideOptions: {
      "customGraph": {
        "isNotAvailable": true
      }
    },
    info: [],
    name: "sex",
    isGrouped: false,
    isScoringItem: true,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "select",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      // nof of M scores
      var nofScores = scores.reduce(function (count, score) {
        count[score] = (count[score] || 0) + 1;
        return count;
      }, {});
      return nofScores.m ? nofScores.m / scores.length : 0;
    },
    modules: []
  };
  moduleTree.modules.push(sex);
  var weight = {
    id: "weight",
    dataset: [],
    isScorable: false,
    multipliers: [],
    range: [0, 100],
    info: [],
    name: "weight",
    isGrouped: true,
    isScoringItem: true,
    isFullWidth: false,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: false,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      return scores.reduce(function (memo, num) {
        return memo + num;
      }, 0) / (scores.length === 0 ? 1 : scores.length);
    },
    modules: []
  };
  var weight_bodyweight = {
    id: "bodyweight",
    abbreviation: "weight",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "bodyweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_bodyweight);
  var weight_bursaweight = {
    id: "bursaweight",
    abbreviation: "bw",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "bursaweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_bursaweight);
  var weight_spleenweight = {
    id: "spleenweight",
    abbreviation: "sw",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [0, "auto"],
    info: [],
    name: "spleenweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: false,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_spleenweight);
  var weight_gizzardweight = {
    id: "gizzardweight",
    abbreviation: "gz-w",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [1, "auto"],
    info: [],
    name: "gizzardweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_gizzardweight);
  var weight_liverweight = {
    id: "liverweight",
    abbreviation: "lv-w",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [1, "auto"],
    info: [],
    name: "liverweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_liverweight);
  var weight_rightventricleweight = {
    id: "rightventricleweight",
    abbreviation: "r-vtrcl-w",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [1, "auto"],
    info: [],
    name: "rightventricleweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_rightventricleweight);
  var weight_totalventricleweight = {
    id: "totalventricleweight",
    abbreviation: "tot-vtrcl-w",
    dataset: [{
      "value": ""
    }],
    isScorable: true,
    multipliers: [],
    range: [1, "auto"],
    info: [],
    name: "totalventricleweight",
    isGrouped: false,
    isScoringItem: false,
    isFullWidth: true,
    isNotClickable: false,
    isHiddenInFilter: false,
    isBigScoring: true,
    scoringType: "input",
    allowEmpty: true,
    isChartOnly: false,
    getDataset: function getDataset() {
      return this.get('dataset').reduce(function (memo, value) {
        memo[value.value] = 0;
        return memo;
      }, {});
    },
    mean: function mean(scores) {
      var validValues = scores.filter(function (v) {
        return v !== '';
      }).map(function (v) {
        return Number(v);
      }).filter(function (value) {
        return value || value === 0;
      });
      return validValues.length ? validValues.reduce(function (sum, score) {
        return sum + score;
      }, 0) / validValues.length : 0;
    },
    modules: []
  };
  weight.modules.push(weight_totalventricleweight);
  moduleTree.modules.push(weight);
  var _default = moduleTree;
  _exports.default = _default;
});