define("huvepharma-aviapp-admin/base/enum/continents/en-my", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "code": "AF",
    "continent": "အာဖရိက"
  }, {
    "code": "NA",
    "continent": "မြောက်အမေရိက"
  }, {
    "code": "OC",
    "continent": "သမုဒ္ဒရာပိုင်း"
  }, {
    "code": "AN",
    "continent": "အန္တာတိက"
  }, {
    "code": "AS",
    "continent": "အာရှ"
  }, {
    "code": "EU",
    "continent": "ဥရောပ"
  }, {
    "code": "SA",
    "continent": "တောင်အမေရိက"
  }];
  _exports.default = _default;
});